let checkLogin = function() {
    fetch('/web-check')
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data === true) {
                location.reload()
            }
        });
}

setInterval(checkLogin, 3000);
